import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from '../../cases.module.css';
import clsx from 'clsx';

interface IDemoVideo {
  source: string;
  poster: string;
  className?: string;
  videoClassName?: string;
  hasWebm?: boolean;
  sourceForMobiles?: string;
  posterForMobiles?: string;
}

export default function DemoVideo({
  source,
  poster,
  posterForMobiles,
  sourceForMobiles,
  className,
  videoClassName,
  hasWebm = true,
}: IDemoVideo) {
  const breakpoint = useBreakpoint();

  const srcWebm =
    !!sourceForMobiles && breakpoint.s
      ? `${process.env.GATSBY_STRAPI_CDN}/video/${sourceForMobiles}.webm`
      : `${process.env.GATSBY_STRAPI_CDN}/video/${source}.webm`;
  const srcMp4 =
    !!sourceForMobiles && breakpoint.s
      ? `${process.env.GATSBY_STRAPI_CDN}/video/${sourceForMobiles}.mp4`
      : `${process.env.GATSBY_STRAPI_CDN}/video/${source}.mp4`;

  const posterSrc = !!posterForMobiles && breakpoint.s ? posterForMobiles : poster;

  return (
    <div className={className ?? styles.videoContainer}>
      <video
        width="100%"
        autoPlay
        loop
        playsInline
        muted
        poster={posterSrc}
        className={clsx(videoClassName, styles.video)}
      >
        {hasWebm && <source src={srcWebm} type="video/webm" />}
        <source src={srcMp4} type="video/mp4" />
      </video>
    </div>
  );
}
