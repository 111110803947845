import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './adact.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Blockquote from '../components/blockquote';
import Technology from 'components/technology';
import Video from '../../layout/video';
import DemoVideo from '../components/demo-video';
//other
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';

const Routes = require('../../routes').Routes;

function AdactCase() {
  const data = useStaticQuery(graphql`
    query {
      andreasUnt: file(relativePath: { eq: "adact/andreas-unt.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kalev: file(relativePath: { eq: "kalev.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sergiyLomako: file(relativePath: { eq: "adact/sergiy-lomako.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      laptop: file(relativePath: { eq: "adact/adact-laptop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      winner: file(relativePath: { eq: "adact/adact-prize.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      campain: file(relativePath: { eq: "adact/adact-campain.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      games: file(relativePath: { eq: "adact/adact-games.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adactScore: file(relativePath: { eq: "adact/adact-score.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      users: file(relativePath: { eq: "adact/adact-users.svg" }) {
        publicURL
      }
      infrastructure: file(relativePath: { eq: "adact/adact-infrastructure.svg" }) {
        publicURL
      }
      adactApps: file(relativePath: { eq: "adact/adact-apps.svg" }) {
        publicURL
      }
      circle1: file(relativePath: { eq: "adact/circle-chart-1.svg" }) {
        publicURL
      }
      circle2: file(relativePath: { eq: "adact/circle-chart-2.svg" }) {
        publicURL
      }
      circle3: file(relativePath: { eq: "adact/circle-chart-3.svg" }) {
        publicURL
      }
      adactTechnicalPrototype: file(relativePath: { eq: "adact/adact-technical-prototype.svg" }) {
        publicURL
      }
      svetaFotoForSafari: file(relativePath: { eq: "sveta-about-martech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svetaFoto: file(relativePath: { eq: "sveta-about-martech-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      video_cover: file(relativePath: { eq: "cover_video_adact.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Martech', 'Estonia', '2021', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Assisting &#10;Adact to develop a&nbsp;marketing gamification platform"
      subTitle="Learn how we helped the&nbsp;leading MarTech company construct the&nbsp;high-loaded solution"
      metaData={meta.adact}
    >
      <DemoVideo source="adact" poster={data.video_cover.childImageSharp.fluid.srcWebp} />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.preamble, styles.preamble)}>
          The cost of customer acquisition is core for digital business. The less expensive it is,
          the higher its growth potential. Companies use plenty of instruments for lead generation,
          but conversion remains challenging. Low user engagement makes campaigns expensive and
          forces marketers to look for more appropriate solutions.
        </p>
        <h2 className={clsx(itemStyles.title, styles.title)}>What is Gamification in Marketing?</h2>
        <p className={itemStyles.paragraph}>
          An intersection of two realms, online games and lead marketing, allowed the development of
          an entirely new approach. Opposite to the traditional way, when digital advertisers
          collect new contacts over static landing pages facing high bounce rates, gamification
          makes conversion real.
        </p>
      </div>
      <Blockquote
        img={data.andreasUnt.childImageSharp.fluid}
        text={`"Case studies show that games have a 16-22% bounce rate, 96% of potential customers finish the game, and 10-30% click the links that follow the game and are ready to become a customer."`}
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        author={
          <React.Fragment>
            <strong>Andreas Unt</strong> <br />
            CMO &amp; Сo-founder at Adact
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          The low bounce rate proves online users' high appreciation of game content. They stay
          longer on the page, and after finishing the play, they are more loyal to sharing their
          contact information and are into receiving future offerings.
        </p>
        <h3 className={styles.subCapture}>
          How do customers behave when they arrive at the gaming page?
        </h3>
        <ul className={styles.stagesList}>
          <li className={styles.listItem} key="Start the game">
            <img src={data.circle1.publicURL} className={styles.logo} alt="Start" />
            <p className={styles.imgSignature}>Start the game</p>
          </li>
          <li className={styles.listItem} key="Complete">
            <img src={data.circle2.publicURL} className={styles.logo} alt="Complete" />
            <p className={styles.imgSignature}>Complete the game</p>
          </li>
          <li className={styles.listItem} key="ready to convert">
            <img src={data.circle3.publicURL} className={styles.logo} alt="ready to convert" />
            <p className={styles.imgSignature}>Click on the next link and ready to convert</p>
          </li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          During a webinar session on creating gamified campaigns{' '}
          <span className={styles.boldFont}>Kalev Kärpuk,</span> CEO and Founder of Adact,
          demonstrated the case for Christmas Campaigns, when 98,97% of players finished the game.
        </p>
      </div>

      <Img fluid={data.laptop.childImageSharp.fluid} className={styles.image} alt="app on laptop" />

      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          In his{' '}
          <a
            href="https://brandification.com/en/2021/07/30/interview-with-kalev-kaerpuk-ceo-founder-adact/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            interview
          </a>{' '}
          to the Brandification website, he explained why gamification works better than traditional
          promotions offering discounts and other benefits to their users:
        </p>
      </div>
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        text='"You provide someone with rewards before they even start doing it. So they feel that they’ve already accomplished something. Place the moment of achievement in the correct place. Users should perform first to feel they deserve some reward."'
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        author={
          <React.Fragment>
            <strong>Kalev Kärpuk</strong> <br />
            CEO and Founder of Adact
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Kalev's statement has scientific ground. Researchers who study emotional intellect claim
          that people do not usually esteem what they haven't endeavored to achieve. So, games
          prompt Internet users to do some actions to gain rewards.
        </p>
      </div>

      <Img fluid={data.games.childImageSharp.fluid} className={styles.imageGames} alt="games" />
      <h2 className={clsx(itemStyles.title, styles.title, styles.innerTitle)}>
        Challenges of the Marketing Gamification
      </h2>
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        text="“Gamification” has been a hot word in marketing for a few years, but it used to be a luxury method that only global companies could afford. The games had to be programmed manually, so they cost $15,000+ and required 2-5 months of work. Additionally, there are a lot of game design decisions that have to be made regarding the game’s length, rules, mechanics, etc.”"
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        author={
          <React.Fragment>
            <strong>Kalev Kärpuk</strong> <br />
            CEO and Founder of Adact
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Meanwhile, the implementation of marketing gamification requires much effort and
          expertise. A team should take care of writing the scenarios for the games, come up with
          the design, develop the code and prepare the server infrastructure. Besides the mentioned,
          the scope should involve the analytics showing the efficacy of the active campaigns.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          The described workflow evidence that customer acquisition could remain an expensive tool
          for marketing industry professionals. As Kalev said, the cost of one campaign could reach
          over $15,000.
        </p>
        <h2 className={clsx(itemStyles.title, styles.blockTitle)}>
          Adact Makes the Marketing Gamification Feasible
        </h2>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Knowing the critical challenges of gamification in marketing, Adact is envisioned to make
          it affordable. It spans the feature set needed to develop bespoke gamification content.
          This is an outbox solution that fully automates the work for digital agencies and brands.{' '}
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          The project we are showcasing to you was done by Kalev Kärpuk, an entrepreneur from
          Estonia. Kalev has been working in the area of marketing gamification since 2016. Before
          developing the side project, he had worked in a New York company. This is where he learned
          much about game design and user behavior. He is grateful for this experience as far as it
          helped him to find his job industry:
        </p>
      </div>
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        text='"It fascinated me and was a cool way to combine my hobby with some expertise. I also teach game design, and it is a really interesting thing for me. I know that it works. And if it works in games, why shouldn’t it work everywhere else?"'
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        author={
          <React.Fragment>
            <strong>Kalev Kärpuk</strong> <br />
            CEO and Founder of Adact
          </React.Fragment>
        }
      />

      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Kalev decided to unite marketing gamification with technology and check if it works in the
          mentioned field. He started up his venture and provided consultancy for his clients. While
          delivering the service, he had discovered a few main goals that gamification helps to
          reach:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bullets)}>
          <li className={styles.listItem}>Generate new leads;</li>
          <li className={styles.listItem}>Leverage drive traffic;</li>
          <li className={styles.listItem}>Increase brand awareness;</li>
          <li className={styles.listItem}>Reduce bounce rate.</li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Kalev utilized <span className={styles.boldFont}>a technical prototype</span> that ensured
          the campaign's run at the initial stage. The MVP was accessible for internal users only
          and was restricted to external customers. His team set the campaigns manually for their
          clients and delivered their service as a contractor. This helped Adact to receive first
          revenue and confirm their product proposition.
        </p>
      </div>
      <img
        src={data.adactTechnicalPrototype.publicURL}
        className={styles.innerImage}
        alt="adact admin's UI"
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          While working on the MVP, Kalev applied to the Startup Wise Guys accelerator (Estonia).
          They were accepted there and successfully finished it by winning their pitch competition.
          This event made Adact attractive to other angel investors so that they have received a few
          significant grants.
        </p>
      </div>
      <div className={clsx(styles.innerImage)}>
        <Img
          fluid={data.winner.childImageSharp.fluid}
          className={clsx(styles.winnersImg)}
          alt="ptoto of winers"
        />
      </div>
      <div className={itemStyles.inner}>
        <p className={styles.imgCaption}>
          Adact, which developed the marketing gamification platform, won one of the top prizes at
          sTARTUp Day. Referenced source:{' '}
          <a href="https://turundajateliit.ee/" target="_blank" rel="noopener noreferrer">
            turundajateliit.ee
          </a>
        </p>
      </div>
      <div className={styles.pinkContainer}>
        <p className={styles.findCandidatesTitle}>
          {' Learn more about Brocoders offer \n for Startup Wise Guys members'}
        </p>
        <p className={styles.findCandidatesText}>
          {
            'Looking for team augmentation, technical support or consultation \n from Startup Wise Guys technical mentor?'
          }
        </p>
        <Link
          id={identificators.SWG_OFFER}
          className={clsx('btn btn_60 btn__white', styles.wideLink)}
          to={Routes.SWG_OFFER}
        >
          Learn more
        </Link>
      </div>
      <div className={itemStyles.inner}>
        <h2 className={clsx(itemStyles.title, styles.blockTitle)}>From Startup to Scale&nbsp;Up</h2>
        <Video
          poster={data.svetaFotoForSafari.childImageSharp.fluid.srcWebp}
          posterWithPlayBtn={data.svetaFoto.childImageSharp.fluid.srcWebp}
          video="adact-final"
        />
        <p className={clsx(itemStyles.paragraph, styles.blockTitle)}>
          The main priority of the Adact team was to implement a user-friendly interface design.
          Since the platform was envisioned as a SAAS, the UI was a key for business. The specifics
          of their model implied the provision of their clients with automated features that must
          facilitate them in creating the gamification campaigns. Based on that, the front-end side
          should streamline the desktop browsers and be straightforward for end-users. Besides the
          advanced UI features, Kalev's expectations were the following:
        </p>
        <p className={itemStyles.paragraph}>
          Besides the advanced UI features, Kalev's expectations were the following:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bullets)}>
          <li className={styles.listItem} key="Smooth onboarding">
            <span className={styles.boldFont}>Smooth onboarding. </span>
            Adact users could create the landing pages as fast as users of WIX, Canvas, and similar
            low-code design platforms.
          </li>
          <li className={styles.listItem} key="Multi-user support">
            <span className={styles.boldFont}>Multi-user support.</span>The platform should consider
            a few types of users such as account owners and team members. Agencies could handle
            campaigns for the brand companies.
          </li>
          <li className={styles.listItem} key="Game templates">
            <span className={styles.boldFont}>Game templates. </span>Users should be enriched with a
            wide range of predefined games which they could select from the beginning.
          </li>
          <li className={styles.listItem} key="Customization">
            <span className={styles.boldFont}>Customization. </span> Campaign creators should be
            able to customize their games by adding various assets related to their brand
            visibility.
          </li>
          <li className={styles.listItem} key="Dashboard analytics">
            <span className={styles.boldFont}>Dashboard analytics. </span>Every manager of the
            campaign could track the statistics of each campaign by viewing the analytics dashboard.
          </li>
          <li className={styles.listItem} key="Advanced performance">
            <span className={styles.boldFont}>Advanced performance. </span>Technically, the system
            should handle the load counting over 2M requests per moment.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          The described scope demanded high expertise in the front-end and back-end, whereas
          performance and usability were the key points. Based on the contemporary web-development
          practice, the mentioned work required such team structure:
        </p>

        <Team
          color="#5475E1"
          list={[
            { count: 1, title: 'Project\nmanager' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Frontend\ndeveloper' },
            { count: 1, title: 'Backend\ndeveloper' },
            { count: 1, title: 'DevOps\nengineer' },
            { count: 2, title: 'Q&A\nspecialists' },
          ]}
          containerClassName={styles.team}
        />
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          As said before, Kalev's team participated in the accelerator program of Estonian incubator
          Startup Wise Guys. Like Adact, Brocoder's team was a resident of that project (in the
          framework of the 16th batch). Our company was presented by Rodion Salnik, who was in
          charge of our side product Casers.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Having learned from Rodion about Brocoder's expertise in developing the high-loaded
          systems, Kalev trusted our team to accomplish the work. Despite our development division
          being located in Ukraine, the production process was organized smoothly. We assigned a
          team for Adact and took on its design after signing an agreement.
        </p>

        <h2 className={clsx(itemStyles.title, styles.blockTitle)}>The Discovery Phase</h2>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Our first step was to figure out the requirements of the project. We learned them during
          the call interviews with Kalev and his team. Luckily, they managed to create the designs
          in Figma that gave us a complete vision regarding future priorities and work milestones.
        </p>
        <h3 className={itemStyles.subtitle}>User Features Requirements</h3>
        <ul className={clsx(itemStyles.bullets, styles.bullets, styles.requirements)}>
          <li className={styles.listItem} key="distinguished by categories">
            Users are distinguished by categories, and they could be agencies and individual
            creators;
          </li>
          <li className={styles.listItem} key=" type of campaign">
            Creators could specify the type of campaign according to their marketing goals;
          </li>
          <li className={styles.listItem} key="template collection">
            Users may pick the game either from a template collection or create it by themselves
            with Adact;
          </li>
          <li className={styles.listItem} key="pick the lead form">
            Creators could pick the lead form and adjust its design. IT should be shown at the end
            of the game;
          </li>
          <li className={styles.listItem} key="game interface">
            The UI design of the landing page, as well as the game interface, should be fully
            customized;
          </li>
          <li className={styles.listItem} key="analytics information">
            Creators could view the analytics information to monitor the efficacy of campaigns;
          </li>
          <li className={styles.listItem} key="brand site">
            The landing page should operate as a standalone website or be embedded into the
            corporate website of the brand site.
          </li>
        </ul>
        <h3 className={itemStyles.subtitle}>The Revision of the Existing Code</h3>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Our next step was to review the existing code and decide on its quality. We wanted to
          figure out whether it would bother the further scale-up or not. In a short while, we found
          out that the project's architecture looked a bit outdated, and was written with PHP and
          jQuery. We suggested Kalev focus on best practice solutions and move forward with React.js
          and Node.js. We chose them as they are suitable for constructing extendable web-based
          products.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          At the end of the discovery phase, we outlined the project features and finalized them in
          the specification. It included explanations of the user stories, requirements on the
          technical solutions, back-end integrations, and the project delivery timeline.
        </p>
      </div>

      <Img
        fluid={data.campain.childImageSharp.fluid}
        className={styles.imageCampain}
        alt="photo of campain"
      />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Building the High-Loaded System</h2>
        <h3 className={itemStyles.subtitle}>Handling the Server-Side Rendering (SSR)</h3>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          The primary requirement of the Adact team was to speed up the content rendering on the
          player's side. Once a user opens the landing page and initiates the game, the data should
          be refreshed in real-time. Any events should be executed smoothly, without delays. Apart
          from our previous approach, when we used the back-end API and talked to many endpoints, we
          decided to arrange the data in single cloud storage. To implement that, we utilized
          Amazon's CloudFront:
        </p>
      </div>
      <Blockquote
        img={data.sergiyLomako.childImageSharp.fluid}
        text={`"Amazon's CloudFront solution matched well with our preferences. Once a user starts playing, the static data is converted to HTML due to our server-side rendering engine and loaded on the user browser. This way, we significantly accelerated the content delivery by avoiding multiple requests to the API. Instead of sending multiple queries, we managed to work through only one."`}
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        author={
          <React.Fragment>
            <strong>Sergiy Lomako</strong> <br />
            Lead Software Engineer
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Besides the mentioned, we utilized the Cloud Delivery Network (CDN) at Amazon. Due to this
          technology, we adjusted the time needed to deliver the content. The network consists of
          the servers which store the cached information. When a user wants to open the home page,
          the CDN refers this query to the nearest web server, minimizing the time for delivery.
        </p>
        <h3 className={itemStyles.subtitle}>Accomplishing the Real-Time Analytics</h3>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          User analytics is one of the key features of Adact. Showing it in real-time helps
          customers to understand the progress of their campaigns. It includes the following
          information:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bullets, styles.requirements)}>
          <li className={styles.listItem}>How many users visited the landing page;</li>
          <li className={styles.listItem}>How many users started the play;</li>
          <li className={styles.listItem}>How many users finished playing;</li>
          <li className={styles.listItem}>How long it took for them to play;</li>
          <li className={styles.listItem}>What score did they play?</li>
          <li className={styles.listItem}>What gifts did they deserve?</li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Before building the analytics, we faced a challenging task. We found out that if we record
          the information on the games, including the score and time of sessions, we will increase
          the database and slow down its work. This went far from our expectations regarding the
          performance. It encouraged us to split the data structure into a few databases. The
          features corresponding to the campaign creation and configuration were accomplished with
          <span className={styles.boldFont}>PostgreSQL</span>. The analytics dashboard was designed
          with <span className={styles.boldFont}>MongoDB</span>. In the meanwhile, both databases
          should communicate with a <span className={styles.boldFont}>single back-end API</span>.
          Hence, we prevented the slow-down on both sides of the web application.
        </p>
        <h3 className={itemStyles.subtitle}>Making the Cost-Efficient Infrastructure</h3>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          We suggested that the founder of Adact use{' '}
          <span className={styles.boldFont}>AWS Lambda</span>. This solution is capable of ensuring
          the scalability of MongoDB. The database could be extended or minimized depending on the
          number of active users. With that help, customers pay only for those resources they used.
        </p>
      </div>
      <img
        src={data.infrastructure.publicURL}
        className={clsx(styles.innerImage, styles.imgAdminUI)}
        alt="adact infrastructure"
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          <span className={styles.boldFont}>Lambda AWS + MongoDB</span> became a pain killer for the
          early-stage startup. At this point, they saved their spending and demonstrated the value
          of their product to the end customers. Due to the proposed architecture, Adact operated in
          conditions of massive traffic, obtaining over 3M visitors in half of a year.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          We also would love to share the case study that became possible with Adact. Lately, the
          client agency has launched a campaign. The outcomes were the following:{' '}
        </p>
        <img src={data.users.publicURL} className={styles.imgUsers} alt="image of users" />
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          In just ten days, the campaign gathered over 18K leads. Which is over 1,800 perspectives
          in a day!
        </p>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          Lately,{' '}
          <a
            href="https://adact.me/pricing/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.underlinedLink}
          >
            Adact has updated its price policy
          </a>
          . You could view it to make sure about the benefits.
        </p>

        <div className={styles.techBlock}>
          <h3 className={clsx(itemStyles.subtitle, styles.techList)}>The List of Technologies</h3>
          <h4 className={styles.techSubList}>Front-end</h4>
          <Technology list={['react', 'nextJs', 'cloudFront']} />
          <h4 className={styles.techSubList}>Back-end</h4>
          <Technology list={['nodejs', 'nest', 'lambda']} />
        </div>
        <div className={styles.databasesBlock}>
          <h4 className={styles.techSubList}>Databases</h4>
          <Technology list={['postgreSQL', 'mongo']} />
        </div>
        <h4 className={styles.techSubList}>Integration with Zapier</h4>
      </div>
      <img
        src={data.adactApps.publicURL}
        className={clsx(styles.imgZapier, styles.innerImage)}
        alt="proto of adact apps"
      />
      <div className={itemStyles.inner}>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          To end our case study, we want to express our happiness to be a partner of Adact. Being a
          co-partner of their team, we gained unparalleled experience building an infrastructure for
          MarTech products.
        </p>
      </div>
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        text={`“Demonstrating unparalleled development expertise, Brocoders has built a bug-free and seamless platform, conquering initial product testing with more than two million interactions. The team delivers quality and timeline-oriented solutions, fostering an agreeable and productive collaboration.”`}
        blockquoteClassName={styles.blockquote}
        innerClassName={styles.blockquoteInner}
        downloadReviewLink="https://hubs.ly/Q01qgRpn0"
        isRating
        author={
          <React.Fragment>
            <strong>Kalev Kärpuk </strong> <br />
            CEO and Founder of Adact
          </React.Fragment>
        }
      />
      <BackgroundImage fluid={data.adactScore.childImageSharp.fluid} className={styles.bg} />
</Layout>
  );
}

export default AdactCase;
